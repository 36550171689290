import React from "react"
import { Container, Col, Row, Image } from "react-bootstrap"
import BenefitsImage from "../../static/benefits.svg"
import Easy from "../images/icons/easy.svg"
import Custom from "../images/icons/customizable-icon.svg"
import New from "../images/icons/new-product-icon.svg"

function Benefits() {
  return (
    <Container>
      <Row className="d-flex align-items-center">
        <Col lg={6} className="benefits-image">
          <img src={BenefitsImage} alt="FiBot benefits" />
        </Col>
        <Col lg={6} className="p-5 py-lg-0">
          <div className="col-12 heading mt-3 section-heading dark">
            <h4>Benefits</h4>
            <span>Why choose FiBot?</span>
          </div>
          <div className="benefits-container">
            <div className="image-wrap">
              <Image src={Easy} alt="Easy" />
            </div>
            <div>
              <h4>Easy, no configuration required</h4>
              <p>
                Get started in no time without any complexity and overhead.
                FiBot takes care of everything for you.
              </p>
            </div>
          </div>
          <div className="benefits-container">
            <div className="image-wrap">
              <Image src={Custom} alt="Customizable" />
            </div>
            <div>
              <h4>Custom tailored solution</h4>
              <p>
                We are commited to providing you the best services that will
                help you achieve your specific goals.
              </p>
            </div>
          </div>
          <div className="benefits-container align-items-start">
            <Image src={New} alt="new features" />
            <div>
              <h4>Access to new features</h4>
              <p>
                We are constantly seeking to improve your experience by adding
                new features and products.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Benefits
