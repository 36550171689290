import React from "react"
import { Container, Col, Row } from "react-bootstrap"

function Stats() {
  return (
    <Container>
      <Row>
        <Col md={3} sm={6} className="stat py-3 py-md-0">
          <span>1M+</span>
          <p>
            Telegram and discord <br />
            notifications sent
          </p>
        </Col>
        <Col md={3} sm={6} className="stat py-3 py-md-0">
          <span>500k+</span>
          <p>Trades executed</p>
        </Col>
        <Col md={3} sm={6} className="stat py-3 py-md-0">
          <span>100k+</span>
          <p>
            Daily average volume <br />
            generated
          </p>
        </Col>
        <Col md={3} sm={6} className="stat py-3 py-md-0">
          <span>76</span>
          <p>Chains</p>
        </Col>
      </Row>
    </Container>
  )
}

export default Stats
