import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Stats from "../components/stats"
import ServicesTabs from "../components/products-services/services-tabs"
import Benefits from "../components/benefits"

const IndexPage = () => {
  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <Seo title="Liquidity and Market Making Services" />

      <section id="services">
        <ServicesTabs />
      </section>

      <section id="benefits">
        <Benefits />
      </section>

      {/* <Row className="g-0">
        <Col>
          <Container id="contact">
            <Contact />
          </Container>
        </Col>
      </Row> */}
    </Layout>
  )
}

export default IndexPage
