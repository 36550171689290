import React from "react"
import { Container, Row, Col, Tab, Nav } from "react-bootstrap"
import NoChartVolume from "../../images/icons/volume-chart.svg"
import LineChart from "../../images/icons/linechart.svg"
import CustomBot from "../../images/icons/fi.svg"
import Airdrop from "../../images/icons/airdrop.svg"
import DcaBot from "../../images/icons/dca.svg"
import API from "../../images/icons/api_1.svg"
import Node from "../../images/icons/node.svg"
import Bubble from "../../images/icons/bubble.svg"
import Whale from "../../images/icons/whale.svg"

import Freebies from "./freebies"
function ServicesTabs() {
  return (
    <Container>
      <Row>
        <div className="col-12 mt-3 section-heading dark center">
          <h4>Services</h4>
          <span>What can FiBot do?</span>
        </div>
      </Row>

      <Tab.Container id="products-tabs" defaultActiveKey="products">
        <Nav variant="pills" className="justify-content-center">
          <div>
            <Nav.Item>
              <Nav.Link eventKey="products">Products</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="services">Services</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="freebies">Freebies</Nav.Link>
            </Nav.Item>
          </div>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="products">
            <Row className="d-flex justify-content-center mt-4">
              <Col md={6} className="d-flex flex-column service-description">
                <Freebies
                  imageSource={NoChartVolume}
                  imageAlt="market making"
                  title="CEX Market Making Bot"
                  description="Generate volume and manage liquidity on centralized exchanges. Our mission 
                  is to help you offer an attractive market for investors to be abe to buy and sell your token."
                  link="https://t.me/FiBotDan"
                  buttonText="Contact us"
                />
              </Col>
              <Col md={6} className="d-flex flex-column service-description">
                <Freebies
                  imageSource={NoChartVolume}
                  imageAlt="market making"
                  title="DEX Volume Bot"
                  description="Generate volume on decentralized exchanges. FiBot can work with
                  hundreds of wallets to achieve natural volume on your chart."
                  link="/marketmaking"
                  buttonText="Read more"
                />
              </Col>
              <Col md={6} className="d-flex flex-column service-description">
                <Freebies
                  imageSource={DcaBot}
                  imageAlt="price stability"
                  title="Cross Exchange Price Stability Bot"
                  description="Token prices can often vary between exchanges, especially when there is low volume or liquidity. Keep the price stable for your coin by using our bot"
                  link="https://t.me/FiBotDan"
                  buttonText="Contact us"
                />
              </Col>
              <Col md={6} className="d-flex flex-column service-description">
                <Freebies
                  imageSource={Airdrop}
                  imageAlt="airdrop"
                  title="Airdrop Bot"
                  description="Promote awareness and circulation of a coin by sending tokens to
                  wallet addresses."
                  link="https://t.me/FiBotDan"
                  buttonText="Contact us"
                />
              </Col>
            </Row>
          </Tab.Pane>
          <Tab.Pane eventKey="services">
            <Row className="d-flex justify-content-center mt-4">
              <Col md={4} className="d-flex flex-column service-description">
                <Freebies
                  imageSource={API}
                  imageAlt="Pricing API"
                  title="Pricing APIs"
                  description="Display real-time data about price, market-cap, volume, supply or anything related on your web page."
                  link="https://t.me/FiBotDan"
                  buttonText="Contact us"
                />
              </Col>
              <Col md={4} className="d-flex flex-column service-description">
                <Freebies
                  imageSource={Node}
                  imageAlt="nodes"
                  title="Node deployment"
                  description="Node infrastructure management, from setting up and maintaining blockchain nodes to configurating different environments for your project"
                  link="https://t.me/FiBotDan"
                  buttonText="Contact us"
                />
              </Col>
              <Col md={4} className="d-flex flex-column service-description">
                <Freebies
                  imageSource={CustomBot}
                  imageAlt="FiBot"
                  title="Custom Bots"
                  description="Custom solutions for prosperous projects. Just contact us and we'll
                  be happy to discuss with you."
                  link="https://t.me/FiBotDan"
                  buttonText="Contact us"
                />
              </Col>
            </Row>
          </Tab.Pane>
          <Tab.Pane eventKey="freebies">
            <Container>
              <Row>
                <h6 className="service-subtitle mb-4">*And it's ad free!</h6>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col md={4} className="d-flex flex-column service-description ">
                  <Freebies
                    imageSource={LineChart}
                    imageAlt="price bot"
                    title="Price Bot"
                    description="Beautiful candlestick or line chart representation, with various key performance statistics."
                    link="/price-bot"
                    buttonText="Set up"
                  />
                </Col>
                <Col md={4} className="d-flex flex-column service-description ">
                  <Freebies
                    imageSource={Bubble}
                    imageAlt="bubble bot"
                    title="Bubble Bot"
                    description="Track purchases of your token and keep your comunity engaged with our bubble bot"
                    link="https://t.me/FiBotDan"
                    buttonText="Request a bot"
                  />
                </Col>
                <Col md={4} className="d-flex flex-column service-description ">
                  <Freebies
                    imageSource={Whale}
                    imageAlt="whales bot"
                    title="Whales Bot"
                    description="Notify your comunity about large and interesting transaction in real time."
                    link="https://t.me/FiBotDan"
                    buttonText="Request a bot"
                  />
                </Col>
              </Row>
            </Container>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  )
}

export default ServicesTabs
