import React from "react"
import { Image, Button } from "react-bootstrap"
import { Link } from "gatsby"

function Freebies({
  imageSource,
  imageAlt,
  title,
  description,
  link,
  buttonText,
}) {
  return (
    <>
      <Image className="service-image" src={imageSource} alt={imageAlt} />
      <h4>{title}</h4>
      <p>{description}</p>
      <Link to={link}>
        <Button>{buttonText}</Button>
      </Link>
    </>
  )
}

export default Freebies
